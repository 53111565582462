<script setup lang="ts">
const { data } = useAuth()
const { signOut } = useSignOut()

const fullName = computed(
  () =>
    `${data.value?.user?.contact?.name} ${data.value?.user?.contact?.lastName}`,
)

async function handleSignOut() {
  await signOut({ redirect: false })
  navigateTo('/login')
}
</script>

<template>
  <div class="h-full w-lvw flex flex-col gap-4">
    <main>
      <header
        class="h-20 shadow-lg px-3 py-4 flex items-center justify-between"
      >
        <div>
          <h2 class="text-base uppercase">
            {{ $t("auth.rapidfire") }}
          </h2>
          <span class="text-xl font-bold mt-2">{{
            $t("projects.request")
          }}</span>
        </div>
        <div class="flex gap-2 pr-3">
          <account-profile-picture
            :name="fullName"
            size="small"
          />
          <pv-button
            icon="pi pi-sign-out"
            text
            rounded
            size="large"
            @click="handleSignOut"
          />
        </div>
      </header>
      <div class="p-9 mt-8">
        <slot />
      </div>
    </main>
  </div>
</template>
